import { lazy } from 'react';

import MainLayout from "../layouts/MainLayout";

import Loadable from "../components/Loadable";
import { RouteObject } from 'react-router-dom';

const ListRequest = Loadable(lazy(() => import('../pages/mainlayout/request/list')))
const FormRequest = Loadable(lazy(() => import('../pages/mainlayout/request/form')))

const ListApproval = Loadable(lazy(() => import('../pages/mainlayout/approval/list')))
const FormApproval = Loadable(lazy(() => import('../pages/mainlayout/approval/form')))

const ListProfilCommunity = Loadable(lazy(() => import('../pages/mainlayout/profilCommunity/list')))
const FormProfilCommunity = Loadable(lazy(() => import('../pages/mainlayout/profilCommunity/form')))

const ListInfluencer = Loadable(lazy(() => import('../pages/mainlayout/influencer/list')))
const FormInfluencer = Loadable(lazy(() => import('../pages/mainlayout/influencer/form')))

const ListInbox = Loadable(lazy(() => import('../pages/mainlayout/inbox/list')))

const Dashboard = Loadable(lazy(() => import('../pages/mainlayout/dashboard')))

const MainRoutes:RouteObject = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: 'dashboard',
            element: <Dashboard />
        },
        {
            path: 'list',
            children: [
                {
                    path: 'request',
                    element: <ListRequest />
                },
                {
                    path: 'approval',
                    element: <ListApproval />
                },
                {
                    path: 'inbox',
                    element: <ListInbox />
                },
                {
                    path: 'influencer',
                    element: <ListInfluencer />
                },
                {
                    path: 'profil-community',
                    element: <ListProfilCommunity />
                },
            ]
        },
        {
            path: 'form',
            children: [
                {
                    path: 'request',
                    element: <FormRequest />,
                    children: [
                        {
                            path: ':id',
                            element: <FormRequest />
                        }
                    ]
                },
                {
                    path: 'approval',
                    element: <FormApproval />,
                    children: [
                        {
                            path: ':id',
                            element: <FormApproval />
                        }
                    ]
                },
                {
                    path: 'profil-community',
                    element: <FormProfilCommunity />,
                    children: [
                        {
                            path: ':id',
                            element: <FormProfilCommunity />
                        }
                    ]
                },
                {
                    path: 'influencer',
                    element: <FormInfluencer />,
                    children: [
                        {
                            path: ':id',
                            element: <FormInfluencer />
                        }
                    ]
                },
            ]
        },
    ]
};

export default MainRoutes;
